import AccordionItem from "../atoms/AccordionItem";
import ProjekteAccordion from "../molecules/ProjekteAccordion";
import Poster from "../../pdf/Poster.pdf";

const Projekte = () => {
  const archive = [
    {
      title: "Sozialpraktikum in Rumänien",
      content:
        "Ich begleitete die Schüler der 11. Klasse der Freien Waldorfschule Leipzig bei ihrem Sozialpraktikum nach Rumänin. In dörflicher Umgebung und einfacher Unterkunft lernten die Schüler die Selbstversorgung und das Leben der Menschen mit all seinen Herausforderungen und Schönheiten kennen.",
    },
    {
      title:
        "KinderUmweltBus der Sächsischen Landesstiftung Natur und Umwelt (LaNU)",
      content:
        "Mobile Umweltbildung für Kindergärten und Schulen zu den Themen Boden, Wasser, Luft und Sonne.",
      link:
        "https://www.lanu.de/de/Bilden/Projekte-Aktionen/Kinderumweltbus.html",
      linktext: "KinderUmweltBus",
    },
    {
      title: "Naturstunde mit Amöba - Verein für Umweltbildung e.V.",
      content:
        "Das Projekt 'Naturstunde' richtet sich an öffentliche Bildungseinrichtungen - insbesondere an Vorschulschüler*innen im Kindergarten und Grundschulschüler*innen an Grundschulen. An 5 Terminen im Jahr 2021 gehen wir mit einer festen Gruppe einer Einrichtung raus, erleben die Natur zu den unterschiedlichen Jahreszeiten, lernen heimische Pflanzen und Tiere kennen und verbringen erholsame Stunden im Wald / Park.",
      link: Poster,
      linktext: "Naturstunde Poster",
    },
    {
      title:
        "Mädchenfahrten mit dem RAA Leipzig - Verein für Interkulturelle Arbeit, Jugendhilfe und Schule e.V.",
      content:
        "Eine Alternative zur Klassenfahrt für Mädchen mit besonderem Förderbedarf. Die mehrtägige Reise in die Natur ermöglicht eine Auszeit und gibt Raum für Kreativität und freie Entfaltung.",
      link: "http://www.raa-leipzig.de/",
      linktext:
        "RAA Leipzig - Verein für Interkulturelle Arbeit, Jugendhilfe und Schule e.V.",
    },
    {
      title: "Erzieher*innenausbildung an der AWO Akademie Mitteldeutschland",
      content:
        "Im Lernfeld Bewegung und Spiel in der Natur für Erzieher*innen im ersten bis dritten Ausbildungsjahr.",
    },
    {
      title: "Schulferiencamps am Wissenschaftspark Leipzig",
      content:
        "Ferienbetreuung von Mitarbeiterkindern des UFZ, TROPOS, IOM und BMFZ mit Exkursionen ins Umland, Beiträgen der Wissenschaftler*innen und Spiel und Spaß auf dem Institutsgelände.",
      link: "http://www.ufz.de/",
      linktext: "Helmholtz-Zentrum für Umweltforschung",
    },
    {
      title: "Projekt Halbe Halbe",
      content:
        'Freie Mitarbeit im Projekt "Halbe-Halbe" der Stadt Leipzig. Das Projekt wird aktuell an mehreren Leipziger Schulen durchgeführt. Mit dem "Halbe-Halbe"-Modell sparen Schulen Energie durch verändertes Nutzerverhalten und erhalten eine Prämie aus den eingesparten Energiekosten.',
      link: "https://halbe-halbe.leipzig.de/",
      linktext: "Projekt Halbe Halbe",
    },
  ];

  return (
    <article className="w-full mx-auto my-8 ">
      <section>
        <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
          Aktuelle Projekte & Kooperationen
        </h2>
        <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pt-8 pb-4 text-center">
          <ProjekteAccordion />
        </div>
      </section>
      <section>
        <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
          Ehemalige Projekte & Kooperationen
        </h2>
        <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pt-8 pb-4 text-center">
        {archive.map((panel, index) => (
          
            <AccordionItem
              title={panel.title}
              content={panel.content}
              link={panel.link}
              linktext={panel.linktext}
              key={index}
            />
        ))}
        </div>
      </section>
    </article>
  );
};

export default Projekte;
