import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/layout/logo.png";
import NavList from "../atoms/NavList";

export default function Header() {
  const [changeMobNav, setChangeMobNav] = useState(false);
  const [classChange, setClassChange] = useState(false);

  const handleMobNav = () => {
    setChangeMobNav(!changeMobNav);
    setClassChange(!classChange);
  };

  return (
    <header>
      <nav className="w-full px-4 pt-2 lg:pt-4 bg-light flex flex-row justify-between items-center lg:items-end fixed">
        <Link
          className="w-1/2 sm:w-2/5 md:w-1/3 lg:w-1/4 mb-4 500:mb-3 md:mb-6 cursor-pointer"
          to="/angebote"
        >
          <img src={logo} alt="Logo der Website" />
        </Link>
        <div onClick={handleMobNav} className="lg:hidden cursor-pointer">
          <div className={classChange ? "changeBar1 bar1" : "bar1"}></div>
          <div className={classChange ? "changeBar2 bar2" : "bar2"}></div>
          <div className={classChange ? "changeBar3 bar3" : "bar3"}></div>
        </div>
        {changeMobNav && <NavList />}
        <div className="hidden lg:block lg:w-2/3">
          <NavList />
        </div>
      </nav>
      <section className="teaserBox pt-20 500:pt-24 md:pt-20 lg:pt-28 ls:pt-40 shadow-md">
        <div className="w-full h-full p-4 md:p-6 lg:p-10 ls:p-12 bg-bluet flex justify-end items-end">
          <p className="font-text text-light text-base 500:text-lg md:text-xl lg:text-2xl ls:text-3xl text-right">
            <span className="font-bold ls:text-4xl">Melanie Heyde</span> <br />
            Umweltbildung <br />
            Naturpädagogik <br />
            Projektkoordination <br />
            Systemische Beratung
          </p>
        </div>
      </section>
    </header>
  );
}
