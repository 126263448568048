const Ausfluege = () => {
  return (
    <article className="w-full mx-auto my-8 ">
      <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
        Gruppenausflüge
      </h2>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pb-4 text-center md:text-left">
        <p className="mt-12 mb-4 lg:mb-10">
          "Zusammen unterwegs sein." Das ist das Motto meiner Gruppenausflüge. Je nach Interessenschwerpunkt gehe ich mit Ihnen wandern, veranstalte eine Lagerküche, leite naturpädagogische Spiele an und/oder referiere zu einem naturnahen Thema im Raum Leipzig.
          <br />
          Zeitlicher Umfang und Kosten können bei Anfrage besprochen werden.
        </p>
      </div>
    </article>
  );
};

export default Ausfluege;
