import Bild1 from "../../img/gallery/Foto6.jpg";
import Bild2 from "../../img/gallery/Foto7.jpg";
import Bild3 from "../../img/gallery/Foto8.jpg";
import Bild4 from "../../img/gallery/Foto9.jpg";
import Bild5 from "../../img/gallery/Foto10.jpg";
import Bild6 from "../../img/gallery/Foto11.jpg";
import Bild7 from "../../img/gallery/Foto12.jpg";
import Bild8 from "../../img/gallery/Foto20.jpg";
import Bild9 from "../../img/gallery/Foto21.jpg";
import Bild10 from "../../img/gallery/Foto22.jpg";
import Bild11 from "../../img/gallery/Foto23.jpg";
import Bild12 from "../../img/gallery/Foto24.jpg";
import Bild13 from "../../img/gallery/Foto25.jpg";
import Bild14 from "../../img/gallery/Foto26.jpg";
import Bild15 from "../../img/gallery/Foto27.jpg";
import Bild16 from "../../img/gallery/Foto28.jpg";
import Bild17 from "../../img/gallery/Foto29.jpg";
import Bild18 from "../../img/gallery/Foto30.jpg";
import Bild19 from "../../img/gallery/Foto31.jpg";
import Bild20 from "../../img/gallery/Foto32.jpg";
import Bild21 from "../../img/gallery/Foto33.jpg";
import Bild22 from "../../img/gallery/Foto34.jpg";
import Bild23 from "../../img/gallery/Foto35.jpg";
import Bild24 from "../../img/gallery/Foto36.jpg";
import Bild25 from "../../img/gallery/Foto37.jpg";
import Bild26 from "../../img/gallery/Foto38.jpg";
import Bild27 from "../../img/gallery/Foto39.jpg";
import Bild28 from "../../img/gallery/Foto40.jpg";
import Bild29 from "../../img/gallery/Foto41.jpg";
import Bild30 from "../../img/gallery/Foto42.jpg";
import Bild31 from "../../img/gallery/Foto43.jpg";
import Bild32 from "../../img/gallery/Foto44.jpg";
import Bild33 from "../../img/gallery/Foto45.jpg";
import Bild34 from "../../img/gallery/Foto46.jpg";
import Bild35 from "../../img/gallery/Foto47.jpg";
import Bild36 from "../../img/gallery/Foto48.jpg";
import Bild37 from "../../img/gallery/Foto49.jpg";
import Bild38 from "../../img/gallery/Foto50.jpg";
import Bild39 from "../../img/gallery/Foto51.jpg";

export default function Gallery() {
  const images = [
    Bild39,
    Bild36,
    Bild35,
    Bild34,
    Bild38,
    Bild37,
    Bild33,
    Bild31,
    Bild32,
    Bild30,
    Bild25,
    Bild23,
    Bild29,
    Bild24,
    Bild28,
    Bild27,
    Bild26,
    Bild22,
    Bild21,
    Bild20,
    Bild19,
    Bild18,
    Bild17,
    Bild16,
    Bild15,
    Bild14,
    Bild13,
    Bild12,
    Bild11,
    Bild10,
    Bild8,
    Bild7,
    Bild6,
    Bild5,
    Bild4,
    Bild3,
    Bild2,
    Bild1,
    Bild9,
  ];

  return (
    <div className="w-11/12 mx-auto my-8 pt-8 pb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
      {images.map((img, i) => (
        <figure key={i}>
          <img src={img} alt="`$img` der Fotogalerie" />
        </figure>
      ))}
    </div>
  );
}
