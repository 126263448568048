import KinderWald from "../../../img/geburtstage/KinderWald.jpg";

const Geburtstage = () => {
  return (
    <article className="w-full mx-auto my-8 ">
      <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
        Kindergeburtstage
      </h2>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pb-4 text-center md:text-left">
        <h3 className="mt-8 md:mt-12 mb-4 md:mb-8 font-title text-3xl xl:text-5xl sm:text-4xl md:text-5xl text-center">
          Kindergeburtstag im Leipziger Auwald
        </h3>
        <p className="mt-12 mb-4 lg:mb-10">
          Kindergeburtstag wild und besinnlich im Wald und am Feuer. Leipzigs
          Auwald bietet einen Reichtum an Natur, in dem wir Deinen Geburtstag
          feiern können – mit Schatzsuche, kooperativen Spielen, Schnitzen und
          Lagerfeuer.
          <br />
          Die Inhalte können je nach Interesse und Alter abgestimmt werden.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center lg:items-start">
          <div>
            <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
              Zeitlicher Umfang:
            </h4>
            <p className="pb-4">ca. 3 Stunden</p>
            <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
              Kosten:
            </h4>
            <p className="pb-4">
              150 EUR inkl. Material
              <br />
              Gruppengröße: 5 - 10 Kinder
              <br />
              Geeignet für Kinder ab 5 Jahre.
              <br />
            </p>
          </div>
          <div className="lg:col-span-2 lg:flex lg:justify-center">
            <figure className="max-w-screen-sm">
              <img src={KinderWald} alt="Kinder laufen durch den Wald" />
            </figure>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Geburtstage;
