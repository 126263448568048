const Impressum = () => {
  return (
    <article>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pt-8 pb-4 text-center md:text-left">
        <h2 className="font-title text-5xl xl:text-6xl text-text text-center md:mb-8">
          Impressum
        </h2>
        <h3 className="my-8 font-title text-dark text-xl lg:text-3xl">
          Angaben gemäss § 5 TMG:
        </h3>
        <div>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Inhaltliche Verantwortung und Domaininhaberin:
          </h4>
          <p>
            Melanie Heyde
            <br />
            Schönbachstr. 50a
            <br />
            04229 Leipzig
            <br />
            Deutschland
          </p>
        </div>
        <div>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Kontakt:
          </h4>
          <p>
            <strong>Mobil:</strong> +49 (0) 176 82 12 59 12
            <br />
            <strong>Email:</strong>{" "}
            <a href="mailto: naturerleben-leipzig@posteo.de">
              naturerleben-leipzig[at]posteo.de
            </a>
            <br />
          </p>
          <p>
            <strong>Steuernummer:</strong> 231/230/11813
          </p>
        </div>
        <div>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Technische Plattform und Design:
          </h4>
          <p>Lena Lehmann</p>
        </div>
        <div className="mb-10">
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Bildnachweise:
          </h4>
          <p>
            Die Bilder auf dieser Website wurden von Melanie Heyde, Ilari
            Liebau, Jochen Pohlink und Alexander Singer zur Verfügung gestellt.
            Das Logo „Natur Erleben Leipzig“ wurde von Marko Raffler erstellt.
          </p>
        </div>
        <hr />
        <h3 className="my-8 font-title text-dark text-xl lg:text-3xl">
          Haftungsausschluss (Disclaimer):
        </h3>
        <div>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            1. Haftung für Inhalte
          </h4>
          <p>
            Die Inhalte meiner Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann ich
            jedoch keine Gewähr übernehmen. Als Diensteanbieter bin ich gemäß §
            7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werde ich diese
            Inhalte umgehend entfernen.
          </p>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            2. Haftung für Links
          </h4>
          <p>
            Mein Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden
            Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
            Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
            verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
            Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
            Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
            permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
            ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
            Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links
            umgehend entfernen.
          </p>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            3. Urheberrecht
          </h4>
          <p>
            Die durch den Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitte ich um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werde ich derartige Inhalte umgehend entfernen.
          </p>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            4. Datenschutz
          </h4>
          <p>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Soweit auf meinen Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
            auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben.
            <br />
            Ich weise darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
            <br />
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit
            ausdrücklich widersprochen. Die Betreiberin der Seiten behält sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
            von Werbeinformationen, etwa durch Spam-Mails, vor.
          </p>
        </div>
      </div>
    </article>
  );
};

export default Impressum;
