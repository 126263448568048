import portrait from "../../img/about/Portrait_MelanieHeyde.jpg";

const About = () => {
  return (
    <article>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pt-8 pb-4 text-center md:text-left">
        <h2 className="font-title text-5xl xl:text-6xl text-text text-center md:mb-8">
          Über mich
        </h2>
        <figure className="w-full 500:w-5/6 md:w-1/2 md:float-left mx-auto md:mx-0 md:mr-8 md:mb-6 mt-10">
          <img
            src={portrait}
            alt="Portrait von Melanie Heyde"
            className="w-full"
          />
        </figure>
        <p className="pt-8 lg:pt-12 pb-4">
        Seit 2010 arbeite ich als Naturpädagogin, Umweltbildnerin und Projektkoordinatorin. Ich
        schaue mit Freude auf zahlreiche Gruppenausflüge, Feriencamps, Weiterbildungen und
        Einzelcoachings mit Kindern, Jugendlichen und Erwachsenen zurück und freue mich auf die
        kommenden Projekte und Veranstaltungen, die mich und Sie in die Natur führen. Meine
        Erfahrung sagt mir, dass dabei der Geist wunderbar zur Ruhe kommt, die Sinne werden
        angeregt und die eigene Fitness positiv beansprucht. Oft sind es die einfachen Dinge, die es zu
        entdecken gilt und dabei nachhaltige Wirkung hinterlassen.

        </p>
        <p className="py-4 clear-left">
          Nach meinem Abitur war ich in Norwegen und anschließend in Sambia bei
          einem solidarischen Projekt tätig. Ich studierte Soziologie sowie
          Pädagogische Psychologie und Humangeografie. Anschließend absolvierte
          ich zahlreiche Weiterbildungen wie Naturpädagogik und Tourenleitung
          mit Schwerpunkt Landschaftserleben. 2022 habe ich die 2-jährigeAusbildung
          zur systemischen Beraterin abgeschlossen. Meine Angebote dazu
          finden Sie <a href="/angebote">hier</a>.
        </p>
        <p className="py-4">
        Nach dem Studium konnte ich für 8 Jahre berufliche Erfahrungen in der Wissenschaft am
        Helmholtz-Zentrum für Umweltforschung sammeln. Zurzeit befinde ich mich in einer
        Anstellung bei der RAA Leipzig e.V. - Verein für interkulturelle Arbeit, Jugendhilfe und
        Schule.
        </p>
        <p className="py-4">
          In meiner Freizeit bin ich begeisterte Schülerin des Iyengar Yoga,
          gehe wandern, pflege einen Garten und betreibe eine Hobbyimkerei.
        </p>
        <p>Ich bin 1980 geboren und lebe mit meinen 2 Kindern in Leipzig.</p>
      </div>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pt-8 pb-4 text-center md:text-left">
        <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
          Über Naturpädagogik
        </h2>
        <p className="pt-8 lg:pt-12 pb-4">
          Die Naturpädagogik vermittelt auf sinnliche und ganzheitliche Art
          Inhalte, die sich um den Zusammenhang zwischen Mensch und Natur
          drehen. Auf spielerische Weise lassen sich Dinge in Erfahrung bringen
          und selbstständig entdecken und erforschen. Dabei spielt das eigene
          Spüren eine wichtige Rolle. Das Erleben in der Natur weckt
          Lebendigkeit, regt die Phantasie an, fördert die eigene Kreativität,
          gibt neue Lebenskraft und stärkt das Selbstvertrauen.
        </p>
        <p className="py-4">
          Der wertschätzende Umgang mit Mensch und Natur, eine respektvolle
          Kommunikation und Achtsamkeit zählen zum Selbstverständnis der
          Naturpädagogik und somit zu meiner Arbeit.
        </p>
      </div>
    </article>
  );
};

export default About;
