import { useState } from "react";
import { useLocation } from "react-router-dom";

export default function AccordionItem(props) {
  const [panelOpen, setPanelOpen] = useState(false);
  const location = useLocation();

  console.log(props);

  return (
    <div className="my-8">
      <p
        className="accordion_title font-bold text-dark"
        onClick={() => setPanelOpen(!panelOpen)}
      >
        {">> " + props.title}
      </p>
      {panelOpen && (
        <div className="py-4 px-2 border border-bluet">
          <div className="text-left">
            <p>{props.content}</p>
            {location.pathname === "/projekte" && props.link && (
              <p className="mb-8">
                zur Website:
                <br />
                <br />
                <a
                  href={props.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue font-bold hover:underline"
                >
                  {">> " + props.linktext}
                </a>
              </p>
            )}
            {location.pathname === "/presse" && props.link && (
              <p className="my-8">
                <a
                  href={props.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue font-bold hover:underline"
                >
                  {">> gesamten Artikel lesen"}
                </a>
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
