import AccordionItem from "../atoms/AccordionItem";
import mmfArticle from "../../pdf/Artikel_MMF_Camp.pdf";
import article2016 from "../../pdf/Artikel_Naturerleben2016.pdf";
import article2020 from "../../pdf/Artikel_Naturerleben2020.pdf";

const PresseAccordion = () => {
  const accordionContent = [
    {
      title:
        "Vogtland-Natur pur begeistert Kinder aus der Großstadt - Artikel vom 31. Juli 2020, Kornbach/Stelzen / Mehltheuer",
      content:
        'Insgesamt 23 Kinder verbrachten jetzt eine gemeinsame Ferienwoche auf der Wieselburg von Naturpädagoge Thomas Hohl aus Kornbach. Weitab von Leipzig, ihrer Heimatstadt, erlebten die Mädchen und Jungen im Alter zwischen acht und 14 Jahre nahe Stelzen fünf Tage mitten in der Natur. "Für einige Kinder ist es das erste Ferienlager überhaupt", berichtete Melanie Heyde, Naturpädagogin in Leipzig. Sie leitete das Feriencamp mit vier weiteren Betreuern.',
      link: article2020,
    },
    {
      title:
        "Naturcamp auf der Wieselburg - Artikel des Sächsischen Mitmach-Fonds",
      content:
        "In den Sommerferien vom 8. – 12. Juli 2019 machten sich 20 Kinder im Altern von 8 bis 14 Jahren und vier Betreuer auf ins Vogtland, um fünf Tage unter freiem Himmel in der Natur zu verbringen. Ziel war die Wieselburg, eine Wagenburg der besonderen Art. Ob schlafen im Tipi oder in den Wagen, Baden und Bootfahren im Teich, Chillen und Herumtollen im Wald, Spurensuche, Tierbeobachtung und Nachtwanderung, Feuerhüten und Experimentieren, Fußball und Bogenschießen, Schnitzen und Glutbrennen – es gab rund um die Uhr etwas zu tun. Dank des Preisgeldes der Sächsischen Mitmach-Fonds konnte der Teilnehmerbetrag sozial verträglich gehalten werden, um das Erlebnis möglichst vielen Kindern und Jugendlichen zu ermöglichen. Des Weiteren konnte ein Betreuungsschlüssel von 1:5 gewährleistet werden.",
      link: mmfArticle,
    },
    {
      title:
        "Eine Woche Abenteuer in der Natur - Artikel vom 04. August 2016, Kornbach/Stelzen / Mehltheuer",
      content:
        '"Hier zu sein, ist ein ganz tolles Erlebnis. Wir haben viel unternommen und auch gelernt, aber wenn es morgen nach Hause geht, ist es auch wieder schön bei der Familie zu sein", lässt sich Martha (11) entlocken. Sie ist mit weiteren vier Leipziger Mädchen unter Leitung der Naturpädagogin Melanie Heyde seit Montag auf der Wieselburg. Dort lebt es sich ungezwungen. Ohne Ankündigung kann ein Besucher nicht erwarten, dass auch nur einer im Camp anzutreffen ist.',
      link: article2016,
    },
  ];

  return (
    <div className="w-full">
      {accordionContent.map((panel, index) => (
        <AccordionItem
          title={panel.title}
          content={panel.content}
          link={panel.link}
          linktext={panel.linktext}
          key={index}
        />
      ))}
    </div>
  );
};

export default PresseAccordion;
