import { useState } from "react";
import { Link } from "react-router-dom";

export default function AngebotBox(props) {
  const [boxOpen, setBoxOpen] = useState(false);

  return (
    <section className="my-4 p-4 border border-blue rounded grid grid-rows-9 gap-4">
      <div className="row-span-4">
        <figure>
          <img src={props.imgSrc} alt={props.imgAlt} />
        </figure>
        {props.link === "" && (
          <div>
            <h3
              className="mt-8 mb-4 font-bold text-blue text-xl"
            >
              {props.title}
            </h3>
            <p className="row-span-4">{props.content}</p>
          </div>
        )}
        {props.link !== "" && (
          <h3
            className="mt-8 mb-4 font-bold text-blue text-xl hover:underline cursor-pointer"
            onClick={() => setBoxOpen(!boxOpen)}
          >
            &gt; {props.title}
          </h3>
        )}
      </div>
      {boxOpen && <p className="row-span-4">{props.content}</p>}
      {props.link === "" && (
        <p className="font-text text-sm text-blue text-right row-span-1">
          ... zur Zeit inaktiv
        </p>
      )}
      {props.link !== "" && (
        <p className="font-text text-sm text-blue text-right row-span-1 hover:underline">
          <Link to={props.link}>... mehr Informationen</Link>
        </p>
      )}
    </section>
  );
}
