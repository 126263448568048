import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from './components/hooks/ScrollToTop';
import About from "./components/pages/About";
import Projekte from "./components/pages/Projekte";
import Angebote from "./components/pages/Angebote";
import Presse from "./components/pages/Presse";
import Impressum from "./components/pages/FooterNav/Impressum";
import Datenschutz from "./components/pages/FooterNav/Datenschutz";
import "./App.css";
import Header from "./components/molecules/Header";
import Footer from "./components/molecules/Footer";
import Naturcamps from "./components/pages/Angebote/Naturcamps";
import CampsGallery from "./components/pages/Angebote/CampsGallery";
import Geburtstage from "./components/pages/Angebote/Geburtstage";
import Projekttage from "./components/pages/Angebote/Projekttage";
import Weiterbildungen from "./components/pages/Angebote/Weiterbildungen";
import Ausfluege from "./components/pages/Angebote/Ausfluege";
import Beratung from "./components/pages/Angebote/Beratung";

export default function App() {
  const paths = [
    { name: "/angebote", component: <Angebote /> },
    { name: "/projekte", component: <Projekte /> },
    { name: "/ueber-mich", component: <About /> },
    { name: "/presse", component: <Presse /> },
    { name: "/angebote/naturcamps", component: <Naturcamps /> },
    { name: "/angebote/naturcamps/galerie", component: <CampsGallery /> },
    { name: "/angebote/kindergeburtstag", component: <Geburtstage /> },
    { name: "/angebote/projekttage", component: <Projekttage /> },
    { name: "/angebote/gruppenausfluege", component: <Ausfluege /> },
    { name: "/angebote/weiterbildung", component: <Weiterbildungen /> },
    { name: "/angebote/beratung", component: <Beratung /> },
    { name: "/impressum", component: <Impressum /> },
    { name: "/datenschutz", component: <Datenschutz /> },
    { name: "/", component: <Angebote /> },
  ];

  return (
    <BrowserRouter>
      <div>
        <Header />
        <main className="w-full p-4 bg-light">
          <Switch>
            {paths.map((path, i) => (
              <Route exact path={path.name} key={i}>
                <ScrollToTop />
                {path.component}
              </Route>
            ))}
          </Switch>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
