import { Link } from "react-router-dom";

export default function NavList() {
  return (
    <ul
      className="
        w-full
        py-4
        px-8
        absolute
        lg:relative
        top-20
        500:top-24
        lg:top-0
        right-0
        bg-light
        bg-opacity-80
        flex
        flex-col
        lg:flex-row
        justify-between
        items-end
      "
    >
      <li className="sm:py-2">
        <Link
          className="antialiased font-title text-2xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl text-blue text-right hover:underline"
          to="/angebote"
        >
          Angebote
        </Link>
      </li>
      <li className="sm:py-2">
        <Link
          className="antialiased font-title text-2xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl text-blue text-right hover:underline"
          to="/projekte"
        >
          Aktuelle Projekte & Kooperationen
        </Link>
      </li>
      <li className="sm:py-2">
        <Link
          className="antialiased font-title text-2xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl text-blue text-right hover:underline"
          to="/ueber-mich"
        >
          Über mich
        </Link>
      </li>
      <li className="sm:py-2">
        <Link
          className="antialiased font-title text-2xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl text-blue text-right hover:underline"
          to="/presse"
        >
          Presse
        </Link>
      </li>
    </ul>
  );
}
