import family from "../../../img/beratung/family-469580_1920.png";
import plant from "../../../img/beratung/plantimage.jpg";
import Beratungsvertrag from "../../../pdf/BeratungsvertragMelanieHeyde.pdf";

const Beratung = () => {
  return (
    <article className="w-full mx-auto my-8 ">
      <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
        Systemische Beratung
      </h2>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto my-8 lg:my-12 pb-4 text-center md:text-left">
        <p className="pb-4">
          Kein Mensch ist eine Insel – John Donne
        </p>
        <p className="pt-2 lg:pt-6 pb-4">
          Bei der Systemischen Beratung biete ich Ihnen einen geschützten Raum, um bedacht und
          analytisch auf Ihren Auftrag zu schauen. Dabei sind Sie selbst Ihr größter Experte und nutzen
          Ihre und die Ressourcen Ihres Systems, um Anliegen ein Stück näher zu kommen. Ich
          begleite Sie in Ihrem Prozess und gebe Anregungen zu neuen Perspektiven.
        </p>
        <figure className="w-1/2 md:w-1/3 xl:w-1/4 mx-auto">
          <img src={family} alt="Bild von Gerd Altmann auf Pixabay" title="Bild von Gerd Altmann auf Pixabay" />
        </figure>
        <p className="pt-2 lg:pt-6 pb-4">
        Ich bin zertifizierte systemische Beraterin (SG).<br />
        Meine Angebote richten sich an Einzelpersonen und Mehrpersonensysteme (Paare, Kleingruppen).
        </p>
      </div>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto my-8 lg:my-12 pb-4 text-center md:text-left">
        <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
          Systemische Beratung im Beratungsraum
        </h4>
        <p className="pb-4">
          Ein Beratungsraum ist ein geschützter Ort. Konzentriert können wir an Ihrem Auftrag arbeiten.<br />
          Dauer: 90 min für das Erstgespräch; 60 min für Folgegespräche<br />
          Kosten pro Sitzung: 60 EUR
        </p>
        <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
          Systmische Beratung via Zoom
        </h4>
        <p className="pb-4">
          Unkompliziert treffen wir uns im Zoom-Raum.<br />
          Dauer: 75 min für das Erstgespräch; 50 min für Folgegespräche<br />
          Kosten pro Sitzung: 50 EUR
        </p>
        <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
          Systmische Beratung in der Natur
        </h4>
        <p className="pb-4">
          Anders als im Beratungsraum oder im Zoom-Raum wird hier die Weite der Natur genutzt. 
          Beim Laufen und Rasten befasse ich mich intensiv mit Ihrem Thema. Eingebunden wird 
          eine naturpädagogische Übung.<br />
          Dauer: 90 min<br />
          Kosten pro Sitzung: 90 EUR
        </p>
      </div>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto my-8 lg:my-12 pb-4 text-center md:text-left">
        <p className="text-left">
          <a href={Beratungsvertrag} target="_blank" rel="noreferrer" className="text-blue font-bold hover:underline">{">>"} Beratungsvertrag zum download</a>
        </p>
      </div>
      <figure className="w-11/12 sm:w-2/3 md:w-1/2 lg:w-2/5 ls:w-1/3 mx-auto">
        <img src={plant} alt="Pflanze von oben" />
      </figure>
    </article>
  );
};

export default Beratung;
