import AngebotBox from "../atoms/AngebotBox";
import camps from "../../img/angebote/angebote_camps.jpg";
import bildung from "../../img/angebote/angebote_weiterbildung.jpg";
import projekttage from "../../img/angebote/angebote_projekttage.jpg";
import geburtstag from "../../img/angebote/angebote_kindergeburtstag.jpg";
import beratung from "../../img/angebote/angebote_beratung.jpg";
import ausfluege from "../../img/angebote/angebote_ausfluege.jpg";

const Angebote = () => {
  const angebotsBoxenKinder = [
    {
      imgSrc: camps,
      imgAlt: "Kinder auf einer Wiese im Sonnenuntergang",
      title: "Naturcamps",
      content:
        "Naturerleben-Leipzig veranstaltet seit 2015 Schülerfreizeiten, die auf Gemeinschaft, Kreativität und Naturerfahrung ausgerichtet sind. Die meiste Zeit verbringen wir draußen, unternehmen kleine Wanderungen mit kooperativen Waldspielen, entdecken die Natur und bauen, basteln, werkeln mit Naturmaterialien. Warme Mahlzeiten werden an der Lagerfeuerstelle zubereitet.",
      link: "/angebote/naturcamps",
    },
    // {
    //   imgSrc: geburtstag,
    //   imgAlt: "zwei Mädchen unter einem Dach aus alten Ästen",
    //   title: "Kindergeburtstage",
    //   content:
    //     "Kindergeburtstag wild und besinnlich im Wald und am Feuer. Leipzigs Auwald bietet einen Reichtum an Natur, in dem wir Deinen Geburtstag feiern können - mit Waldrallye, kooperativen Spielen, Schnitzen und Lagerfeuer.",
    //   link: "/angebote/kindergeburtstag",
    // },
    {
      imgSrc: geburtstag,
      imgAlt: "zwei Mädchen unter einem Dach aus alten Ästen",
      title: "Kindergeburtstage",
      content:
        "",
      link: "",
    },
    // {
    //   imgSrc: projekttage,
    //   imgAlt: "Kinder mit Keschern auf einer Wiese",
    //   title: "Projekttage an Schulen, im Hort & in der KiTa",
    //   content:
    //     "Für Projekttage konzipiere ich Angebote, die dem Interesse und dem Lehrplan (bei Schulen) entsprechen.",
    //   link: "/angebote/projekttage",
    // },
    {
      imgSrc: projekttage,
      imgAlt: "Kinder mit Keschern auf einer Wiese",
      title: "Projekttage an Schulen, im Hort & in der KiTa",
      content: "",
      link: "",
    },
  ];

  const angebotsBoxenErwachsene = [
    // {
    //   imgSrc: ausfluege,
    //   imgAlt: "Bauwagen mit Zetteln beklebt",
    //   title: "Gruppenausflüge",
    //   content:
    //     "Mit Freunden oder Kollegen draußen unterwegs sein, ein gemeinsames Süppchen über dem Feuer kochen, eine Waldrallye unternehmen und dabei gemeinsam Aufgaben lösen, den Teamgeist schulen… Dies und vieles mehr bieten meine Angebote für Gruppen.",
    //   link: "/angebote/gruppenausfluege",
    // },
    {
      imgSrc: ausfluege,
      imgAlt: "Bauwagen mit Zetteln beklebt",
      title: "Gruppenausflüge",
      content: "",
      link: "",
    },
    // {
    //   imgSrc: bildung,
    //   imgAlt: "Bauwagen mit Zetteln beklebt",
    //   title: "Weiterbildung",
    //   content:
    //     "In einer Weiterbildung für Sie und Ihr Team können Sie in die Welt der Naturpädagogik eintauchen. Sie lernen dabei vielseitige Methoden und Übungen zum Naturerleben und zur Wissensvermittlung rund um die Natur kennen.",
    //   link: "/angebote/weiterbildung",
    // },
    {
      imgSrc: bildung,
      imgAlt: "Bauwagen mit Zetteln beklebt",
      title: "Weiterbildung",
      content: "",
      link: "",
    },
    {
      imgSrc: beratung,
      imgAlt: "Portrait von Melanie Heyde",
      title: "Systemische Beratung",
      content:
        "Kein Mensch ist eine Insel – John Donne. Bei der Systemischen Beratung biete ich Ihnen einen geschützten Raum, um bedacht und analytisch auf Ihren Auftrag zu schauen.",
      link: "/angebote/beratung",
    },
  ];

  return (
    <article className="w-full mx-auto my-8 ">
      <div className="w-full sm:w-4/5 lg:w-1/2 ls:w-1/3 mx-auto mb-8 pt-8 pb-4 text-center">
        <h3 className="font-bold text-dark text-sm lg:text-lg">
          "Was du mir sagst, das vergesse ich. <br />
          Was du mir zeigst, daran erinnere ich mich. <br />
          Was du mich tun lässt, das verstehe ich."
        </h3>
        <p className="font-text text-text text-xs lg:text-sm text-right">
          (Konfuzius, 500 v. Chr.)
        </p>
      </div>
      <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
        Mein Angebot
      </h2>
      <section className="w-11/12 sm:w-4/5 xl:w-2/3 mx-auto my-16">
        <p className="text-center">
          Meine Angebote erstrecken sich von Projekttagen an Schulen und
          Kindergärten, über Weiterbildungen und Gruppenausflüge bis hin zu
          Ferienfreizeiten und Kindergeburtstagen in der Natur. Gern gestalte
          ich Ihnen ein individuelles Angebot nach Ihren Vorstellungen und
          Wünschen. Wenn Sie eigene Ideen haben, die Sie mit meiner
          Unterstützung umsetzen möchten, kontaktieren Sie mich gern.
        </p>
      </section>
      <section className="w-11/12 sm:w-3/4 mx-auto">
        <h3 className="mt-8 md:mt-12 mb-4 md:mb-8 font-title text-3xl xl:text-5xl sm:text-4xl md:text-5xl text-center">
          Angebote für Kinder & Jugendliche
        </h3>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 ls:grid-cols-3 gap-4">
          {angebotsBoxenKinder.map((box, i) => (
            <AngebotBox
              imgSrc={box.imgSrc}
              imgAlt={box.imgAlt}
              title={box.title}
              content={box.content}
              link={box.link}
              key={i}
            />
          ))}
        </div>
      </section>
      <section className="w-11/12 sm:w-3/4 mx-auto flex flex-col">
        <h3 className="mt-8 md:mt-12 mb-4 md:mb-8 font-title text-3xl xl:text-5xl sm:text-4xl md:text-5xl text-center">
          Angebote für Familien & Erwachsene
        </h3>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 ls:grid-cols-3 gap-4">
          {angebotsBoxenErwachsene.map((box, i) => (
            <AngebotBox
              imgSrc={box.imgSrc}
              imgAlt={box.imgAlt}
              title={box.title}
              content={box.content}
              link={box.link}
              key={i}
            />
          ))}
        </div>
      </section>
    </article>
  );
};

export default Angebote;
