const Weiterbildungen = () => {
  return (
    <article className="w-full mx-auto my-8 ">
      <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
        Weiterbildungen
      </h2>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pb-4 text-center md:text-left">
        <p className="mt-12 mb-4 lg:mb-10">
        In einer Weiterbildung können Sie in die Welt der Naturpädagogik 
        eintauchen. Sie lernen dabei vielseitige Methoden und Übungen zum 
        Naturerleben und zur Wissensvermittlung rund um die Natur kennen. Die 
        Vermittlung ist natur- und praxisnah. Durch Selbsterfahrung können die 
        Inhalte gut verinnerlicht werden.
        </p>
        <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
          Themen:
        </h4>
        <p>
          <ul className="my-4 px-8 list-disc">
            <li>Lern- und Erlebnisraum Wiese</li>
            <li>Lern- und Erlebnisraum Wald</li>
            <li>Landart</li>
            <li>Kräuterwerkstatt</li>
            <li>Lagerfeuerküche</li>
            <li>Kooperative Spiele zur Schulung des Teamgeistes</li>
          </ul>
        </p>
        <div>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Dauer:
          </h4>
          <p className="pb-4">
            1-5 Tage zu jeweils 6 - 8 Stunden täglich.<br />
            Sie können den zeitlichen Rahmen bestimmen.
          </p>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Teilnehmerzahl:
          </h4>
          <p className="pb-4">6-16 Personen (Sie melden sich und Ihr Team an)</p>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Ort:
          </h4>
          <p className="pb-4">
            Dies ist ein mobiles Angebot. Ich komme zu Ihnen in die Einrichtung,
            in den nahe gelegenen Wald oder Park. Bei Aktivitäten mit Feuer
            findet die Veranstaltung an der Feuerstelle Friesenstr. (Lindenau)
            oder Marienweg (Gohlis) statt.
          </p>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Kosten:
          </h4>
          <p className="pb-4">
            Die Kosten können bei Anfrage besprochen werden.
          </p>
        </div>
      </div>
    </article>
  );
};

export default Weiterbildungen;
