import { Link } from "react-router-dom";
import FormSommer2021 from "../../../pdf/AnmeldungsformularSommercamp2023.pdf";

const Naturcamps = () => {
  return (
    <article className="w-full mx-auto my-8 ">
      <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
        Naturcamps
      </h2>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pt-12 pb-4 text-center md:text-left">
        <p className="font-bold text-lg text-blue hover:underline">
          <Link to="/angebote/naturcamps/galerie">{">>"} zur Fotogalerie</Link>
        </p>
      </div>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pb-4 text-center md:text-left">
        <p className="pb-4">
          Die Naturcamps sind auf Gemeinschaft, Kreativität, Bewegung, Erholung
          und Naturerfahrung ausgerichtet. Unter freiem Himmel bereiten wir die
          Mahlzeiten an der Feuerstelle zu. Wir unternehmen kleine Tag- und
          Nachtwanderungen, richten uns ein Waldlager her, machen Spiele,
          entdecken die Natur, bauen, basteln und werkeln. Im Sommer wird der
          nahe gelegene Teich zum Baden und Bootfahren genutzt.
        </p>
        <p className="pt-2 lg:pt-6 pb-4">
          Die Camps sind für Schüler*innen zwischen 8 und 14 Jahren geeignet.
          Eine gewisse Selbstständigkeit ist erforderlich.
        </p>
        <p className="pt-2 lg:pt-6 pb-4">
          Der Betreuungsschlüssel im Naturcamp richtet sich nach der
          Teilnehmer*innenzahl und liegt in etwa bei 1:5. Die Betreuer*innen
          haben einen pädagogischen Hintergrund und/oder haben Sonderkenntnisse
          in der Natur-/ Wald-/ Wildnis- oder Erlebnispädagogik.
        </p>
      </div>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pt-8 md:pt-0 pb-4 text-center md:text-left">
        <h3 className="mt-8 mb-4 md:mb-8 font-title text-3xl xl:text-5xl sm:text-4xl md:text-5xl text-center">
          Nächste Termine:
        </h3>
        <h4 className="my-8 font-bold text-dark text-lg lg:text-xl">
          Naturcamp Sommerferien
        </h4>
        <div className="w-full grid grid-cols-2 gap-2">
          <p className="text-left">
            Termin:
          </p>
          <p className="text-left">
            10. bis 14. Juli 2023
          </p>
          <p className="text-left">
            Ort:
          </p>
          <p className="text-left">
            Stelzen im Vogtland, Wieselburg
          </p>
          <p className="text-left">
            Besonderes:
          </p>
          <p className="text-left">
            Übernachtung im Bauwagen, max. 20 Teilnehmer*innen
          </p>
          <p className="text-left">
            Kosten:
          </p>
          <p className="text-left">
            280 € bei gemeinsamer An- und Abreise mit der Gruppe ab und nach Leipzig<br />
            250 € bei selbstständiger An- und Abreise zum und vom Camp
          </p>
        </div>
        <p className="text-left">
          <a href={FormSommer2021} target="_blank" rel="noreferrer" className="text-blue font-bold hover:underline">Anmeldeformular öffnen</a>
        </p>
        <p className="pt-16 lg:pt-12">
          Im Preis inbegriffen sind 5 Tage Unterkunft, Geländepauschale, Bio-Vollverpflegung, Materialien, Betreuung.
        </p>
        <p className="pt-2">
          Weitere Details zum Ablauf des Camps erhalten Sie nach Anmeldung bzw. auf Nachfrage.
        </p>
        <p className="pt-2 lg:pt-6 pb-4">
          Eine Bezuschussung der Kosten von max. 180,00 EUR ist über BuT möglich. Informationen dazu sowie Anträge finden Sie auf <a href="https://leipzig.de" target="_blank" rel="noreferrer">www.leipzig.de</a>.
        </p>
      </div>
    </article>
  );
};

export default Naturcamps;
