import Gallery from "../../molecules/Gallery";

const CampsGallery = () => {
  return (
    <article className="w-full mx-auto my-8 ">
      <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
        Fotogalerie vergangener Naturcamps
      </h2>
      <Gallery />
    </article>
  );
};

export default CampsGallery;
