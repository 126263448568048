import { Link } from "react-router-dom";
import ESFLogo from "../../img/layout/SMWA_ESF_LO_Sachsen_2019_QUER_RGB.png";

export default function Footer() {
  return (
    <footer className="w-full bg-blue">
      <div className="w-full md:w-4/5 lg:w-11/12 mx-auto p-4 lg:p-0 lg:pb-12 flex flex-col lg:flex-row justify-between items-center lg:items-start">
        <section className="lg:w-1/3 ls:w-1/4 mt-6 lg:mt-10 text-light text-center lg:text-left">
          <h2 className="my-4 font-title text-5xl">Kontakt</h2>
          <h3 className="my-2 font-bold text-xl">Melanie Heyde</h3>
          <p className="my-2 text-light">
            E-Mail:
            <br />
            naturerleben-leipzig[at]posteo.de
          </p>
          <p className="my-2 text-light">
            Telefon: <br />
            +49 (0) 176 82 12 59 12<br />
            +49 (0) 341 12 57 93 01
          </p>
        </section>
        <section className="lg:w-2/5 mt-6 lg:mt-10 text-light text-center lg:text-left">
          <h2 className="my-4 font-title text-5xl">Projektkoordination</h2>
          <p className="my-2 text-light">
            Naturcamps des{" "}
            <a href="http://amoeba-umweltbildung.de">
              Amöba - Verein für Umweltbildung e.V.
            </a>
          </p>
          <p className="my-2 text-light">
            finanziert aus Mitteln der Europäischen Union und des Freistaates
            Sachsen
          </p>
          <figure className="w-11/12 mx-auto my-6">
            <img src={ESFLogo} alt="Logo des ESF" />
          </figure>
          <p className="my-2 text-light">
            ausgewählt für die Auszeichnung in der UN-Dekade Biologische
            Vielfalt im Rahmen des Sonderwettbewerbs "Soziale Natur - Natur für
            alle"
          </p>
        </section>
        <section className="w-full lg:w-1/5 lg:h-40 mt-10 mb-6 text-center lg:text-right flex flex-row lg:flex-col justify-around items-center lg:items-start">
          <Link
            className="w-2/5 lg:w-full font-title text-light text-4xl lg:text-5xl hover:underline"
            to="/impressum"
          >
            Impressum
          </Link>
          <Link
            className="w-2/5 lg:w-full font-title text-light text-4xl lg:text-5xl hover:underline"
            to="/datenschutz"
          >
            Datenschutz
          </Link>
        </section>
      </div>
    </footer>
  );
}
