import PresseAccordion from "../molecules/PresseAccordion";

const Presse = () => {
  return (
    <article className="w-full mx-auto my-8 ">
      <section>
        <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
          Meine Projekte in der Presse
        </h2>
        <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pt-8 pb-4 text-center">
          <PresseAccordion />
        </div>
      </section>
    </article>
  );
};

export default Presse;
