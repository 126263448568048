import AccordionItem from "../atoms/AccordionItem";

const ProjekteAccordion = () => {
  const accordionContent = [
    {
      title: "ESF-Schülercamps mit Amöba - Verein für Umweltbildung e.V.",
      content:
        "Die Schülercamps, finanziert aus Mitteln des Europäischen Sozialfonds und des Freistaates Sachsen, haben den Schwerpunkt Lernförderung und Kompetenztraining. Mittels naturpädagogischer Übungen und sozialpädagogischer Betreuung werden die Teilnehmer in ihren Kompetenzen und Fähigkeiten geschult und gestärkt.",
      link: "https://amoeba-umweltbildung.de/projekte/naturcamps",
      linktext: "Amöba - Verein für Umweltbildung e.V.",
    },
    {
      title: "Naturpädagogisches Projekt beim Städtische Eigenbetrieb Behindertenhilfe (SEB) Leipzig",
      content:
        "Bei diesem Projekt unternehme ich mit Bewohner:innen aus verschiedenen Einrichtungen des SEB Ausflüge in die Natur. Wir lernen die naturnahe städtische Umgebung kennen und die Bewohner:innen versuchen sich je nach Fähigkeiten kleinen Herausforderungen zu stellen.",
    },
    {
      title:
        "Kräuterwerkstatt am Ökologischen Landwirtschaftsschulheim Dreiskau-Muckern (Oekola)",
      content:
        "Ein klasseninterner Workshop rund um das Thema Kräuter und deren Verarbeitung zu Kräutersalz und Limonade.",
      link: "http://www.oekola7.de",
      linktext: "Ökologisches Landwirtschaftsschulheim Dreiskau-Muckern",
    },
    {
      title: "Projekt MFM",
      content:
        "Referentin bei MFM e.V. - My Fertility Matters. Ein ergänzendes Angebot zum Biologieunterricht für Mädchen in der Klassenstufe 5 - 7 mit Inhalt: körperliche Entwicklung und Veränderung, Zyklus und Fruchtbarkeit, mein Körper und meine Gesundheit.",
      link: "https://www.mfm-programm.de/",
      linktext: "Projekt MFM",
    },
  ];

  return (
    <div className="w-full">
      {accordionContent.map((panel, index) => (
        <AccordionItem
          title={panel.title}
          content={panel.content}
          link={panel.link}
          linktext={panel.linktext}
          key={index}
        />
      ))}
    </div>
  );
};

export default ProjekteAccordion;
