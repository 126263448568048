const Projekttage = () => {
  return (
    <article className="w-full mx-auto my-8 ">
      <h2 className="font-title text-5xl xl:text-6xl text-text text-center">
        Projekttage an Schulen, im Hort und in KiTas
      </h2>
      <div className="w-11/12 lg:w-3/4 ls:w-2/3 mx-auto mb-8 pb-4 text-center md:text-left">
        <p className="mt-12 mb-4 lg:mb-10">
          Für Projekttage oder Klassenausflüge zum Thema Natur und Umwelt biete
          ich folgende Themen an:
          <ul className="my-4 px-8 list-disc">
            <li>Lern- und Erlebnisraum Wiese</li>
            <li>Lern- und Erlebnisraum Wald</li>
            <li>Feuer und Wildnisküche</li>
            <li>Kräuterwerkstatt</li>
            <li>Landart</li>
            <li>Spiel & Bewegung in der Natur</li>
            <li>Soziales Lernen in der Natur</li>
          </ul>
        </p>
        <div>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Dauer:
          </h4>
          <p className="pb-4">
            Die Veranstaltungen dauern zwei bis sechs Stunden. Sie können den
            zeitlichen Rahmen bestimmen.
          </p>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Teilnehmerzahl:
          </h4>
          <p className="pb-4">
            6-16 Schüler*innen
            <br /> (bei mehr als 16 Schüler*innen ist die Unterstützung einer
            erwachsenen Person (wie Hortner*in, Lehrer*in, Erzieher*in)
            erforderlich)
          </p>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Ort:
          </h4>
          <p className="pb-4">
            Dies ist ein mobiles Angebot. Ich komme zu Ihnen in die Einrichtung,
            in den nahe gelegenen Wald oder Park. Bei Aktivitäten mit Feuer
            findet die Veranstaltung an der Feuerstelle Friesenstr. (Lindenau)
            oder Marienweg (Gohlis) statt.
          </p>
          <h4 className="my-8 font-bold text-dark text-base lg:text-lg">
            Kosten:
          </h4>
          <p className="pb-4">
            Inkl. An-und Abfahrt im Raum Leipzig sowie Material: <br />
            <br />
            2 Stunden 120,00 EUR <br />
            3 Stunden 150,00 EUR <br />
            jede weitere Stunde 50,00 EUR
          </p>
        </div>
      </div>
    </article>
  );
};

export default Projekttage;
